<template>
	<div>
		<el-row>
			<el-col>
				<el-form :inline='true'>
<!--					<el-form-item label="是否启用:">-->
<!--						<el-select v-model="filters.isEnable" clearable>-->
<!--							<el-option-->
<!--									v-for="item in optionsData"-->
<!--									:key="item.value"-->
<!--									:label="item.label"-->
<!--									:value="item.value">-->
<!--							</el-option>-->
<!--						</el-select>-->
<!--					</el-form-item>-->
					<el-form-item label="关键字：">
						<el-input v-model="filters.keyword" placeholder="请输入单位名称" clearable></el-input>
					</el-form-item>
					<el-form-item>
						<toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
					</el-form-item>
				</el-form>
			</el-col>
		</el-row>
		<!--列表-->
		<el-table
				:data="listData"
				highlight-current-row
				v-loading="listLoading"
				border=""
				@current-change="selectCurrentRow"
				style="width: 98%;margin:0 auto"
		>
			<el-table-column type="index" width="60" align="center" label="序号"></el-table-column>
			<el-table-column prop="Name" align="center" label="单位名称"  show-overflow-tooltip></el-table-column>
			<el-table-column prop="CreditNo" align="center" label="统一社会信用代码"  show-overflow-tooltip></el-table-column>
			<el-table-column prop="OperName" align="center" label="法人"  show-overflow-tooltip></el-table-column>
			<el-table-column prop="RegNo" align="center" label="注册号"  show-overflow-tooltip></el-table-column>
			<el-table-column prop="StartDate" align="center" label="成立日期"  show-overflow-tooltip></el-table-column>
			
		</el-table>
		<!--底部工具条-->
		<el-col :span="24" class="pageBar">
			<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pages.pageIndex"
					:page-sizes="pages.pageArr"
					:page-size="pages.pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="pages.dataCount">
			</el-pagination>
		</el-col>
		<!--新增/编辑界面-->
		<el-dialog
				:title="addOrEdi"
				:visible.sync="addFormVisible"
				v-model="addFormVisible"
				:close-on-click-modal="false"
				@close='handleClose'
				width="40%"
		>
			<el-form label-width="150px" :model="formData" :rules="formDataRules" ref="formData" >
				<el-form-item label="单位名称:" prop="Name">
					<el-input v-model="formData.Name" style="width: 80%" placeholder="请输入单位名称" ></el-input>
				</el-form-item>
				<el-form-item label="社会信用代码:" prop="CreditNo">
					<el-input v-model="formData.CreditNo" style="width: 80%" placeholder="请输入统一社会信用代码" ></el-input>
				</el-form-item>
				<el-form-item label="法人:" prop="OperName">
					<el-input v-model="formData.OperName" style="width: 80%" placeholder="请输入法人姓名" ></el-input>
				</el-form-item>
				<el-form-item label="注册号:" prop="RegNo">
					<el-input v-model="formData.RegNo" style="width: 80%" placeholder="请输入注册号" ></el-input>
				</el-form-item>
				<el-form-item label="成立日期:" prop="StartDate">
					<el-date-picker
							v-model="formData.StartDate"
							type="date"
							style="width: 80%"
							value-format="yyyy-MM-dd"
							placeholder="选择日期">
					</el-date-picker>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click.native="addFormVisible = false">取消</el-button>
				<el-button type="primary" @click.native="addSubmit" :loading="addLoading" v-if="addOrEdi != '查看'">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Toolbar from "../../components/Toolbar"
import { getButtonList } from "../../promissionRouter";
import {
	getReportDataSetting,
	deletedReportDataSettingByBatch,
	getAreaDictionaryList,
	getReportDataSettingByBatch,
	saveReportDataSettingByBatch,
	isEnableReportDataSettingForBatch,
	getJuheDataItems, updateItem, searchExtraItemByName, deleteExtraItem
} from '@/api/api'
export default {
	components: {Toolbar},
	data() {
		return{
			filters: {
				keyword : undefined,
			},
			buttonList: [],
			listData: [],
			listLoading: false,
			GDcityData: [],
			
			formData:{
				CreditNo:'',//统一社会信用代码
				Name : '',//公司名称
				OperName: '',//法人姓名
				RegNo: '',//注册号
				StartDate: '',//成立日期
			},
			formDataRules : {
				CreditNo: [
					{ required: true, message: '请输入统一社会信用代码', trigger: 'blur' }
				],
				Name: [
					{ required: true, message: '请输入单位名称', trigger: 'blur' }
				],
				OperName: [
					{ required: true, message: '请输入法人姓名', trigger: 'blur' }
				],
				RegNo: [
					{ required: true, message: '请输入注册号', trigger: 'blur' }
				],
				StartDate: [
					{ required: true, message: '请选择成立日期', trigger: 'change' }
				],
			},
			
			addFormVisible: false,
			addLoading: false,
			addOrEdi: "查看",
			currentRow:null, // 当前行
			//关于分页的obj
			pages: {
				pageSize: 20,//默认每页条数
				pageArr: [10, 20, 30, 40],//默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
		}
	},
	methods: {
		// 查询
		getReportDataFun() {
			this.pages.pageIndex = 1
			this.getListData();
		},
		// 新增
		handleAdd() {
			this.addOrEdi = "新增"
			this.formData={
				CreditNo:'',//统一社会信用代码
				Name : '',//公司名称
				OperName: '',//法人姓名
				RegNo: '',//注册号
				StartDate: '',//成立日期
			}
			this.addFormVisible = true;
		},
		// 编辑
		handleEdit(){
			this.addOrEdi = "编辑"
			if(this.currentRow == null){
				this.$message({
					message:'请选择编辑的单位！',
					type:'warning'
				})
			}else{
				this.formData = {
					...this.currentRow
				}
				this.addFormVisible = true;
			}
		},
		// 删除
		handleDel(){
			let row = this.currentRow
			
			if(row == null){
				this.$message({
					message:'请选择要删除的一行数据',
					type:'warning'
				})
			}else{
				this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					console.log(row,'rouw')
					let params = {
						id: row.Id
					}
					deleteExtraItem(params).then(res => {
						if(res.data.Success){
							setTimeout(()=>{
								this.getListData()
								this.$message({
									message:res.data.Message,
									type:'success'
								})
							},800)
						}else{
							this.$message({
								message:res.data.Message,
								type:'error'
							})
						}
					}).catch(() => {})
				}).catch(() => {})
			}
		},
		
		callFunction(item) {
			this[item.Func].apply(this, item);
		},
		// 获取数据
		getListData() {
			let params = {
				pageNum: this.pages.pageIndex,
				pageSize: this.pages.pageSize,
				keyword : this.filters.keyword ,//企业关键字
			}
			this.listLoading = true;
			searchExtraItemByName(params).then(res => {
				let result = res.data
				if(res.data.Success){
					this.pages.dataCount = result.Response.NumberOfElements
					this.pages.pageIndex = result.Response.TotalPages
					if(result.Response.PageSize > 0){
						this.pages.pageSize = result.Response.Size
					}
					this.listData = res.data.Response.Content
				}
				this.listLoading = false
			})
		},
		// 点击table某一行
		selectCurrentRow(val) {
			this.currentRow = val
		},
		// 提交表单
		addSubmit() {
			this.$refs['formData'].validate((valid) => {
				if (valid) {
					let params = {
						...this.formData,
					}
					updateItem(params).then(res => {
						if (this.addOrEdi === "编辑"){
							if (res.data.Success){
								this.$message({
									message:'编辑成功！',
									type:'success'
								})
								this.addFormVisible = false;
								setTimeout(()=>{
									this.getListData()
								},800)
							}else {
								this.$message({
									message:'编辑失败！',
									type:'error'
								})
							}
						}else {
							if (res.data.Success){
								
								this.addFormVisible = false;
								this.$message({
									message:'新增成功！',
									type:'success'
								})
								setTimeout(()=>{
									this.getListData()
								},800)
							}else {
								this.$message({
									message:'新增失败！',
									type:'error'
								})
							}
						}
					})
				}
			})
			// console.log(this.formData,'formdata')
		},
		// 分页
		handleCurrentChange(val) {
			this.pages.pageIndex = val;
			this.getListData();
		},
		handleSizeChange(val){
			this.pages.pageSize = val;
			this.getListData();
		},
		// 表单清空
		handleClose(){
			this.$refs['formData'].resetFields()
		},
		// 时间转换
		dateFormat(format,date){
			let ret = ''
			date = new Date(date)
			const option = {
				'Y+':date.getFullYear().toString(),
				'm+':(date.getMonth() + 1).toString(),
				'd+':date.getDate().toString(),
				'H+':date.getHours().toString(),
				'M+':date.getMinutes().toString(),
				'S+':date.getSeconds().toString()
			}
			for(let k in option){
				ret = new RegExp('('+k+')').exec(format)
				if(ret){
					format = format.replace(
							ret[1],
							ret[1].length == 1? option[k] :option[k].padStart(ret[1].length,'0')
					)
				}
			}
			return format
		}
	},
	mounted() {
		this.getListData();
		let routers = window.localStorage.router
				? JSON.parse(window.localStorage.router)
				: [];
		this.buttonList = getButtonList(this.$route.path, routers);
	},
}
</script>

<style lang="stylus" scoped>
.viwesTitle {
	font-weight bold;
	text-align center;
	font-size 18px;
	margin-bottom 15px;
}
.viwesContent {
	text-indent:2em;
	font-size 16px;
	line-height 26px;
	text-align justify;
}
.el-textarea>>>.el-textarea__inner {
	min-height 150px!important;
}
// .pageBar{
//     margin-top:15px!important;
// }
.avatar {
	width: 100%;
	height: 100%;
	display: block;
}
.pictureViews img{
	width 130px!important;
	max-height 150px!important;
	margin-right 10px;
}
.inputTipsStr {
	color: #888;
}
</style>